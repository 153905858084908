import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import { Container, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: "#ffffff",
    padding: "80px 0px",
  },
  sectionBlack: {
    backgroundColor: "#333333",
    padding: "80px 0px",
    color: "#ffffff",
  },
  sectionBeige: {
    backgroundColor: "#f2dec5",
    padding: "60px 0px",
    color: "#2d271e",
  },
  sectionHeading: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "80px",
  },
}))

const Section = ({ children, heading, color = "white", size = "lg" }) => {
  const classes = useStyles()

  return (
    <div
      className={
        color === "black"
          ? classes.sectionBlack
          : color === "beige"
          ? classes.sectionBeige
          : classes.section
      }
    >
      <Container maxWidth={size}>
        {heading && (
          <Typography variant="h4" className={classes.sectionHeading}>
            {heading}
          </Typography>
        )}
        {children}
      </Container>
    </div>
  )
}

export default Section
