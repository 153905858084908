import React from "react"
import Step from "./Step"

import lecture from "../../images/lecture.jpg"
import homework from "../../images/homework.jpg"
import group from "../../images/group.jpg"
import questions from "../../images/questions.jpg"

const Steps = () => (
  <>
    <Step
      title="Watch Live Lectures"
      text="A live Zoom lecture will be held once per week. You can either come along to the lecture as it's happening, or you can't make it to lecture, the recording will be available online."
      image={lecture}
      alt="Zoom lecture"
    />
    <Step
      title="Complete Homework"
      text="Each week, you'll be watching videos, reading assignments, and coding for yourself. The course is structured so that you can do a minimum of 1 hour per week, or a maximum of 10 hours per week."
      image={homework}
      imageLeft={true}
      alt="Girl doing homework"
    />
    <Step
      title="Attend Group Chats"
      text="If you decide to sign up to group chats, then you will be sorted into a small group with other students for weekly check-in calls. Here, you will show off your homework, keep yourself accountable, and connect with fellow learners."
      image={group}
      alt="Group Zoom call"
    />
    <Step
      title="Get Help"
      text="The course comes with a Discord server, where you'll be able to ask any questions that come up as you're learning. There will also be 'Office Hours', where you'll be able to ask questions directly to the instructor."
      image={questions}
      imageLeft={true}
      alt="Question mark"
    />
  </>
)

export default Steps
