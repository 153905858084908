import React from "react"
import { Grid, Typography, makeStyles } from "@material-ui/core"

import adam from "../../images/adam.jpg"

const useStyles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  box: {
    border: "2px #ffffff solid",
    textAlign: "center",
    padding: "40px",
    borderRadius: "2%"
  },
  heading: {
    textAlign: "center",
  },
  image: {
    borderRadius: "50%",
    height: "250px",
  },
  text: {
    textAlign: "justify",
    padding: "40px",
    fontSize: "1.3em",
  },
})

const About = () => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} className={classes.flex}>
        <div className={classes.box}>
          <img src={adam} alt="Adam" className={classes.image} />
          <Typography variant="h5" className={classes.heading}>
            Adam Janes
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.flex}>
        <Typography variant="body1" className={classes.text}>
          Adam first learned to code as a student at Harvard university and has
          been a full-stack developer for the past 6 years. He works as a
          digital nomad, and teaches the best-selling course on data visualization
          on Udemy.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default About
