import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Button } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  tagline: {
    textAlign: "center",
    fontSize: "calc(105% + 4.3vw)",
    lineHeight: 1.38,
    fontWeight: "bold",
    letterSpacing: "0.035em",
  },
  subheading: {
    marginTop: "20px",
    color: "#756858",
    fontSize: "22px",
    lineHeight: 1.8,
    fontWeight: 400,
    textAlign: "center",
  },
  button: {
    maxWidth: "280px",
    width: "100%",
    alignSelf: "center",
  },
  link: {
    marginTop: "40px",
    width: "100%",
    color: "inherit",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center"
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
}))

const Splash = () => {
  const classes = useStyles()

  return (
    <div className={classes.flexCol}>
      <Typography variant="h4" className={classes.tagline}>
        Learn web development in just 10 weeks.
      </Typography>
      <Typography variant="h6" className={classes.subheading}>
        WOOFCODE is a free camp to teach you how to code.
      </Typography>
      <a className={classes.link} href="http://eepurl.com/hm6zGX">
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          size="large"
          elevation={0}
        >
          Sign up now
        </Button>
      </a>
    </div>
  )
}

export default Splash
