import React from "react"
import { Grid, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  box: {
    border: "1px #ffffff solid",
    textAlign: "center",
    padding: "60px 40px",
    borderRadius: "2%"
  },
  name: {
    textAlign: "right",
    paddingTop: "10px",
    fontSize: "1.1rem",
    fontStyle: "italic",
  },
})

const Testimonial = ({ name, text }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} md={6}>
      <div className={classes.box}>
        <Typography variant="body1" className={classes.text}>
          {text}
        </Typography>
        <Typography variant="body1" className={classes.name}>
          {`- ${name}`}
        </Typography>
      </div>
    </Grid>
  )
}

export default Testimonial
