import React from "react"
import { Grid, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  icon: {
    fontSize: "70px",
  },
  feature: {
    textAlign: "center",
    padding: "30px 20px"
  },
  title: {
    margin: "20px 0px"
  }
})

const Feature = ({ Icon, title, text }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} md={4} className={classes.feature}>
      <Icon className={classes.icon} />
      <Typography variant="h6" className={classes.title}>{title}</Typography>
      <Typography variant="body2">{text}</Typography>
    </Grid>
  )
}

export default Feature
