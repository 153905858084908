import React from "react"
import { Grid } from "@material-ui/core"
import {
  WatchLaterRounded,
  MonetizationOnRounded,
  GroupWorkRounded,
} from "@material-ui/icons"
import Feature from "./Feature"

const Features = () => (
  <Grid container>
    <Feature
      Icon={WatchLaterRounded}
      title="Learn Fast"
      text="In only 10 weeks, you'll know enough to build a whole application by yourself."
    />
    <Feature
      Icon={MonetizationOnRounded}
      title="Pay What You Want"
      text="The course is taught on a donation-only basis. Pay whatever you think is fair."
    />
    <Feature
      Icon={GroupWorkRounded}
      title="Stay Accountable"
      text="Stick to your learning pathway with weekly group chats with other students."
    />
    {/* <Typography variant="body">{text}</Typography> */}
  </Grid>
)

export default Features
