import React from "react"
import HomeLayout from "../layouts/HomeLayout"
import SEO from "../components/seo"

import Section from "../components/Home/Section"
import Splash from "../components/Home/Splash"
import Features from "../components/Home/Features"
import About from "../components/Home/About"
import Logos from "../components/Home/Logos"
import Steps from "../components/Home/Steps"
import Testimonials from "../components/Home/Testimonials"
import CTA from "../components/Home/CTA"

// const useStyles = makeStyles(theme => ({}))

const IndexPage = ({ data }) => {
  // const classes = useStyles()

  return (
    <HomeLayout>
      <SEO title="Home" />
      <Section size="md">
        <Splash />
      </Section>
      <Section color="black">
        <Features />
      </Section>
      <Section heading={"How it works"} >
        <Steps />
      </Section>
      <Section heading={"Your instructor"} color="black">
        <About />
      </Section>
      <Section heading={"What you'll learn"}>
        <Logos />
      </Section>
      <Section heading={"What people are saying"} color="black">
        <Testimonials />
      </Section>
      <Section size="md">
        <CTA />
      </Section>
    </HomeLayout>
  )
}

export default IndexPage
