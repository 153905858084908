import React from "react"
import { Grid, makeStyles } from "@material-ui/core"

import Logo from "./Logo"

import html from "../../images/html-logo.svg"
import css from "../../images/css-logo.svg"
import javascript from "../../images/javascript-logo.svg"
import react from "../../images/react-logo.svg"
import mongo from "../../images/mongo-logo.svg"
import express from "../../images/express-logo.svg"
import node from "../../images/node-logo.svg"
import materialUi from "../../images/material-ui-logo.svg"

const useStyles = makeStyles({
  logos: {
    maxWidth: "100vw"
  }
})

const Logos = () => {
  const classes = useStyles()
  return (
    <Grid container spacing={8} className={classes.logos}>
      <Logo image={html} alt="HTML logo" />
      <Logo image={css} alt="CSS logo" />
      <Logo image={javascript} alt="JavaScript logo" />
      <Logo image={react} alt="React logo" />
      <Logo image={materialUi} alt="Material-UI logo" />
      <Logo image={node} alt="Node.js logo" />
      <Logo image={mongo} alt="MongoDB logo" />
      <Logo image={express} alt="Express.js logo" />
    </Grid>
  )
}

export default Logos
