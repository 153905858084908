import React from "react"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    height: "100px",
    marginBottom: 0,
  },
})

const Logo = ({ image, alt }) => {
  const classes = useStyles()
  return (
    <Grid item xs={6} sm={4} md={3} className={classes.container}>
      <img src={image} alt={alt} className={classes.logo} />
    </Grid>
  )
}

export default Logo
