import React from "react"
import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"

const useStyles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  heading: {
    textAlign: "center",
    paddingBottom: "10px",
  },
  text: {
    textAlign: "justify",
    padding: "20px",
    fontSize: "18px",
  },
  image: {
    height: "300px",
    borderRadius: "3%",
    marginBottom: 0,
  },
  container: {
    maxWidth: "100vw"
  }
})

const Step = ({ title, text, image, imageLeft = false, alt }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  })

  return (
    <Grid
      container
      spacing={isMobile ? 5 : 10}
      direction={imageLeft ? "row" : "row-reverse"}
      className={classes.container}
    >
      <Grid item xs={12} sm={6} className={classes.flex}>
        <img src={image} alt={alt} className={classes.image} />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.flex}>
        <Typography variant="h5" className={classes.heading}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Step
