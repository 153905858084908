import React from "react"
import { Link } from "gatsby"
import { Grid, Typography, Button, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  tagline: {
    textAlign: "center",
    fontSize: "calc(105% + 5.3vw)",
    lineHeight: 1.38,
    fontWeight: "bold",
    letterSpacing: "0.035em",
  },
  subheading: {
    marginTop: "20px",
    color: "#756858",
    fontSize: "22px",
    lineHeight: 1.8,
    fontWeight: 400,
    textAlign: "center",
  },
  button: {
    maxWidth: "280px",
    width: "100%",
    alignSelf: "center",
  },
  link: {
    marginTop: "40px",
    width: "100%",
    color: "inherit",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
}))

const CTA = () => {
  const classes = useStyles()

  return (
    <div className={classes.flexCol}>
      <Typography variant="h4" className={classes.tagline}>
        The Winter 2021 course started on January 5th.
      </Typography>
      <a className={classes.link} href="http://eepurl.com/hm6zGX">
        <Button
          className={classes.button}
          variant="contained"
          size="large"
          color="primary"
        >
          Subscribe
        </Button>
      </a>
    </div>
  )
}

export default CTA
