import React from "react"
import { Grid } from "@material-ui/core"
import Testimonial from "./Testimonial"

import adam from "../../images/adam.jpg"

const Testimonials = () => (
  <Grid container spacing={6} style={{ maxWidth: "100vw" }}>
    <Testimonial
      name="Sarah R"
      text="Adam is a wonderful instructor who clearly explains all of the steps involved in becoming a developer. I would recommend this course to anyone who wants to dive into programming languages and website development."
    />
    <Testimonial
      name="Naveen M"
      text="An amazing teacher, a power-packed course, and a fantastic community! Adam's cohort-based course on was just the right springboard for me to start building my app from scratch. Highly recommended for everyone!"
    />
    <Testimonial
      name="Diego V"
      text="The way that the class was organized helped me a lot to learn web dev. The projects were also very relatable to real-world applications and I enjoyed navigating through others' code to learn from them as well."
    />
    <Testimonial
      name="Lindy L"
      text="Even though I didn’t have enough time to keep on track, Adam kept on supporting me to show up for classes and group chats. He went way further supporting the students than I expected from a teacher of an online course."
    />
  </Grid>
)

export default Testimonials
